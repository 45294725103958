/** @jsx jsx */
import { graphql } from 'gatsby';
import { jsx, Flex, Box } from 'theme-ui';

import Layout from '../../components/layout';
import MediaWrapper from '../../components/project/media-wrapper';
import { renderRichText } from '../../helpers/richText';

const Project = ({ data }) => {
  const {
    title,
    client = '',
    tags,
    description,
    backgroundColour,
    mediaWrapper,
  } = data.contentfulProject;
  // console.log(data);
  return (
    <Layout sx={{ bg: backgroundColour || `unset`}}>
      <Flex  sx={{ bg: `white`, flexWrap: `wrap`}}>
        <Box sx={{ flex: ['100%',,1], p: ['1rem 28px',,'1rem 58px'], pb: [0,,`2rem`] }}>
          <p sx={{ fontSize: `22px`, marginBottom: 0, marginLeft: `3px`}}>{client}</p>
          <h2 sx={{ fontSize: `58px`, fontWeight: 400, margin: `8px 0px`, lineHeight: `68px`}}>{title}</h2>
          <p sx={{ fontSize: `16px`, fontWeight: 300, marginTop: 0, marginLeft: `3px`}}>{tags?.join(' | ')}</p>
        </Box>
        <Box sx={{ flex: 1, p: ['2rem 28px 2rem 0',,'2rem 58px 2rem 0'], pl: [`28px`,,0], pt: [0,,`2rem`], fontSize: `16px` }}>
          {description && renderRichText(description)}
        </Box>
      </Flex>
      {mediaWrapper?.map((item) => (
        <MediaWrapper key={item.id} {...item} />
      ))}
    </Layout>
  );
};

export default Project;

export const query = graphql`
  query ($id: String) {
    contentfulProject(id: { eq: $id }) {
      id
      title
      client
      tags
      description {
        raw
      }
      backgroundColour
      mediaWrapper {
        ... on ContentfulMediaWrapper {
          __typename
          id
          padding
          marginTop
          backgroundColour
          backgroundImage {
            file {
              url
            }
          }
          media {
           ... on ContentfulProjectImage {
              __typename
              image {
                gatsbyImageData
              }
              id
              maxWidth
              marginTop
              marginBottom
              marginLeft
              marginRight
            }
            ... on ContentfulVimeo {
              __typename
              id
              vimeoUrl
              maxWidth
              originalWidth
              originalHeight
              loop
              marginTop
              marginBottom
              marginLeft
              marginRight
            }          
          }
        }
      }
    }
  }
`;
