/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { GatsbyImage } from 'gatsby-plugin-image';

const Image = (media) => {
    const { marginTop, marginBottom, marginLeft, marginRight } = media;
    const maxWidth = media.maxWidth ? `${media.maxWidth}px` : `100%`
    // console.log(media);
return (
    <Box sx={(t) => ({
        maxWidth: maxWidth,
        marginTop: marginTop ? `${marginTop}px` : `0`,
        marginBottom: marginBottom ? `${marginBottom}px` : `0`,
        marginLeft: marginLeft ? `${marginLeft}px` : `0`,
        marginRight: marginRight ? `${marginRight}px` : `0`,
      })}>
        <GatsbyImage loading="lazy" image={media?.image?.gatsbyImageData} alt="" />
    </Box>
)
}

export default Image