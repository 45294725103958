/** @jsx jsx */
import { Flex, jsx } from 'theme-ui';
import Image from './image';
import Vimeo from './vimeo';

const MediaWrapper = (mediaWrapper) => {
    const {backgroundColour, backgroundImage, marginTop, padding, media } = mediaWrapper;
    // console.log(media);
    // console.log(`mediaWrapper: `, mediaWrapper)

    const getComponent = (item) => {
        switch (item.__typename) {
          case 'ContentfulProjectImage':
            return <Image {...item} />;

          case 'ContentfulVimeo':
            return <Vimeo {...item} />;
    
          default:
            return null;
        }
      };

return (
 
    <Flex sx={{
      justifyContent: `center`,
      backgroundColor: backgroundColour || `unset`,
      backgroundImage: backgroundImage?.file?.url ? `url(${backgroundImage?.file?.url})` : `none`,
      paddingX: padding ? `${padding}rem` : `0`,
      marginX: "auto",
      backgroundPosition: `center`,
      backgroundRepeat: `no-repeat`,
      backgroundSize: `contain`,
      marginTop: marginTop ? `${marginTop}rem` : `0`}}>
        {media?.map(item => getComponent(item))}
    </Flex>
    
)
}

export default MediaWrapper
