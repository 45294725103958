import * as React from 'react';
import styled  from '@emotion/styled'

import getVideoId from 'get-video-id'

const Vimeo = (media) => {
  const { vimeoUrl, maxWidth, originalWidth, originalHeight, loop, marginTop, marginBottom, marginLeft, marginRight } = media
    const videoId = getVideoId(vimeoUrl).id
    const playerUrl = videoId ? `https://player.vimeo.com/video/${videoId}?controls=${loop ? `0` : `1`}&muted=${loop ? `1` : `0`}&autoplay=${loop ? `1` : `0`}&loop=${loop ? `1` : `0`}&autopause=0&pip=0` : null
    const setMaxWidth = maxWidth ? `${maxWidth}px` : `100%`

    const setPadding = originalWidth && originalHeight ? `${(originalHeight / originalWidth) * 100}%` : `56.25%`
    
    if (!playerUrl) {
        return <div>Bad bad video url..</div>
    }

    return (
        <VideoOuter maxWidth={setMaxWidth} marginBottom={marginBottom} marginTop={marginTop} marginLeft={marginLeft} marginRight={marginRight}>
         <VideoInner setPadding={setPadding}>
        
          <Player
            src={playerUrl}
            // title={name || null}
            // width="640"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
           </VideoInner>
        </VideoOuter>
      )
}

export default Vimeo

const VideoOuter = styled.div`
  width: 100%;
  max-width: ${props => props.maxWidth};
  margin-top: ${props => props.marginTop ? `${props.marginTop}px` : `0`};
  margin-bottom: ${props => props.marginBottom ? `${props.marginBottom}px` : `0`};
  margin-left: ${props => props.marginLeft ? `${props.marginLeft}px` : `0`};
  margin-right: ${props => props.marginRight ? `${props.marginRight}px` : `0`};
`

const VideoInner = styled.div`
  padding-top: ${props => props.setPadding};
  height: 0px;
  position: relative;
`

const Player = styled.iframe`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`